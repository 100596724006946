import React, { CSSProperties } from 'react';
import styled, { useTheme } from 'styled-components';

const StyledSvg = styled.svg`
  width: 653px;
  height: 513px;
`;

export const MapSvg = ({ ...props }: { style?: CSSProperties }) => {
  const { colors } = useTheme();
  return (
    <StyledSvg
      width="653"
      height="514"
      viewBox="0 0 653 514"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="230.5"
        y="1.5"
        width="70"
        height="30"
        stroke={colors.graphs.map.line.primary}
        strokeWidth="3"
      />
      <rect
        x="230.5"
        y="31.5"
        width="101"
        height="480"
        stroke={colors.graphs.map.line.primary}
        strokeWidth="3"
      />
      <rect
        x="90.5"
        y="51.5"
        width="140"
        height="121"
        stroke={colors.graphs.map.line.primary}
        strokeWidth="3"
      />
      <rect
        x="1.5"
        y="51.5"
        width="89"
        height="121"
        stroke={colors.graphs.map.line.primary}
        strokeWidth="3"
      />
      <rect
        x="331.5"
        y="1.5"
        width="230"
        height="129"
        stroke={colors.graphs.map.line.primary}
        strokeWidth="3"
      />
      <rect
        x="331.5"
        y="1.5"
        width="160"
        height="129"
        stroke={colors.graphs.map.line.primary}
        strokeWidth="3"
      />
      <rect
        x="1.5"
        y="172.5"
        width="229"
        height="169"
        stroke={colors.graphs.map.line.primary}
        strokeWidth="3"
      />
      <rect
        x="561.5"
        y="130.5"
        width="90"
        height="381"
        stroke={colors.graphs.map.line.secondary}
        strokeWidth="3"
      />
      <rect
        x="331.5"
        y="130.5"
        width="230"
        height="381"
        stroke={colors.graphs.map.line.primary}
        strokeWidth="3"
      />
      <rect
        x="1.5"
        y="342.5"
        width="229"
        height="170"
        stroke={colors.graphs.map.line.primary}
        strokeWidth="3"
      />
      <line
        x1="242"
        y1="1.5"
        x2="281"
        y2="1.5"
        stroke={colors.background.primary}
        strokeWidth="3"
      />
      <line
        x1="242"
        y1="31.5"
        x2="281"
        y2="31.5"
        stroke={colors.background.primary}
        strokeWidth="3"
      />
      <line
        x1="331.5"
        y1="42"
        x2="331.5"
        y2="81"
        stroke={colors.background.primary}
        strokeWidth="3"
      />
      <line
        x1="491.5"
        y1="62"
        x2="491.5"
        y2="101"
        stroke={colors.background.primary}
        strokeWidth="3"
      />
      <line
        x1="230.5"
        y1="89"
        x2="230.5"
        y2="128"
        stroke={colors.background.primary}
        strokeWidth="3"
      />
      <line
        x1="90.5"
        y1="92"
        x2="90.5"
        y2="131"
        stroke={colors.background.primary}
        strokeWidth="3"
      />
      <line
        x1="230.5"
        y1="203"
        x2="230.5"
        y2="242"
        stroke={colors.background.primary}
        strokeWidth="3"
      />
      <line
        x1="230.5"
        y1="355"
        x2="230.5"
        y2="394"
        stroke={colors.background.primary}
        strokeWidth="3"
      />
      <line
        x1="561.5"
        y1="286"
        x2="561.5"
        y2="325"
        stroke={colors.background.primary}
        strokeWidth="3"
      />
      <line
        x1="331.5"
        y1="203"
        x2="331.5"
        y2="510"
        stroke={colors.background.primary}
        strokeWidth="3"
      />
    </StyledSvg>
  );
};
