import React from 'react';
import styled, { useTheme } from 'styled-components';

const StyledSvg = styled.svg`
  width: 228px;
  height: 229px;
  ${({ theme }) => theme.mediaQueries.md} {
    width: 150px;
    height: auto;
  }
`;

export const LoginIcon = () => {
  const { colors } = useTheme();
  return (
    <StyledSvg
      viewBox="0 0 228 229"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.321289 102.518V186.098C0.321289 209.294 19.1253 228.098 42.3213 228.098H185.679C208.875 228.098 227.679 209.294 227.679 186.098V102.518C227.679 90.0924 222.176 78.3041 212.651 70.324L140.973 10.2714C125.367 -2.80273 102.633 -2.80272 87.0274 10.2714L15.3487 70.324C5.8237 78.3041 0.321289 90.0924 0.321289 102.518Z"
        fill={colors.background.primary}
      />
      <path
        d="M124.821 65.4891C124.821 70.8771 119.844 75.5381 113.321 75.5381C106.798 75.5381 101.821 70.8771 101.821 65.4891C101.821 64.1541 102.327 61.9009 103.245 59.1385C104.147 56.4264 105.394 53.3774 106.759 50.5184C108.129 47.6523 109.595 45.0254 110.924 43.1379C111.592 42.1894 112.191 41.4778 112.692 41.0198C113.036 40.7044 113.242 40.5945 113.321 40.5568C113.401 40.5945 113.606 40.7044 113.951 41.0198C114.452 41.4778 115.05 42.1894 115.718 43.1379C117.048 45.0254 118.514 47.6523 119.883 50.5184C121.249 53.3774 122.496 56.4264 123.397 59.1385C124.316 61.9009 124.821 64.1541 124.821 65.4891Z"
        stroke="url(#paint0_linear_149_35605)"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M87.3213 107.277L121.524 107.277C131.101 107.277 128.365 93.0381 122.892 93.0381C117.42 93.0382 117.693 98.9419 117.693 98.9419M130.006 109.013C130.006 109.013 131.159 109.014 135.781 109.013C140.404 109.013 140.418 101.373 136.3 101.373C132.182 101.373 133.29 105.193 133.29 105.193M92.7937 113.181L126.958 113.181C133.837 113.181 133.837 124.294 128.324 124.294C122.812 124.294 127.544 118.39 123.132 118.873M96.6245 118.043L113.245 118.043C118.514 118.043 118.647 127.767 113.245 127.767C107.843 127.767 109.758 121.169 111.947 122.905"
        stroke="url(#paint1_linear_149_35605)"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M121.946 161.018H124.946M121.946 166.267H122.696M121.946 171.518H124.946M106.195 149.767C106.195 146.453 108.881 143.767 112.195 143.767C115.508 143.767 118.195 146.453 118.195 149.767V175.372C121.782 177.446 124.196 181.325 124.196 185.767C124.196 192.395 118.824 197.767 112.196 197.767C105.569 197.767 100.196 192.395 100.196 185.767C100.196 181.326 102.609 177.449 106.195 175.374V149.767Z"
        stroke="url(#paint2_linear_149_35605)"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_149_35605"
          x1="98.3213"
          y1="39.0381"
          x2="122.821"
          y2="79.5381"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#01E4F2" />
          <stop offset="1" stopColor="#0144F2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_149_35605"
          x1="120.321"
          y1="131.311"
          x2="99.3213"
          y2="92.8111"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EA3480" />
          <stop offset="1" stopColor="#343BD7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_149_35605"
          x1="102.196"
          y1="144.54"
          x2="123.696"
          y2="193.54"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EB0000" />
          <stop offset="1" stopColor="#F68500" />
        </linearGradient>
      </defs>
    </StyledSvg>
  );
};
